import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment/moment";

const ListDetails = () => {
  const { registrationTotal } = useSelector((store) => store.registerAll);

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "NAME",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "fname",
      headerName: "FATHER NAME",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "course",
      headerName: "COURSE",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "certificate",
      headerName: "CERTIFICATE",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "grade",
      headerName: "GRADE",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "date",
      headerName: "DATE",
      width: 150,
      headerAlign: "center",

      renderCell: (params) => {
        return <>{moment(params.createdAt).format("MMMM DD YYYY")}</>;
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div
            style={{ height: 650, width: "100%" }}
            className="d-flex m-auto text-align-center"
          >
            <DataGrid
              rows={registrationTotal}
              columns={columns}
              getRowId={(row) => row._id}
              pageSize={10}
              rowsPerPageOptions={[10]}
              // checkboxSelection
            />
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ListDetails;
