import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./redux/Athentication/AuthSlice";
import registerAllReducer from "./redux/registration/RegistrationSlice";
import ContactAllReducer from "./redux/contact/ContactSlice";
import BannerAllReducer from "./redux/banner/BannerSlice";
import NotificationAllReducer from "./redux/notification/NotificationSlice";


export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    registerAll : registerAllReducer,
    ContactAll : ContactAllReducer,
    BannerAll : BannerAllReducer,
    NotificationAll : NotificationAllReducer,
  },
});