import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import axios from "axios";

const ListContacts = () => {
  const { contactTotal } = useSelector((store) => store.ContactAll);
  console.log(contactTotal);

  const dispatch = useDispatch();

  const deleteClick = async (e, id) => {};

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "firstname",
      headerName: "NAME",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "mobile",
      headerName: "MOBILE",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "EMAIL",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "text",
      headerName: "TEXT",
      width: 500,
      align: "center",
      headerAlign: "center",
    },

    
  ];

  return (
    <>
      {/* {categoryLoading ? (
        <div className="load">
          <ClipLoader color={"#D0021B"} loading={categoryLoading} size={30} />
        </div>
      ) : ( */}
      <Fragment>
        <div className="content-wrapper ">
          <div
            style={{ height: 650, width: "100%" }}
            className="d-flex m-auto text-align-center"
          >
            <DataGrid
              rows={contactTotal}
              columns={columns}
              getRowId={(row) => row._id}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ListContacts;
