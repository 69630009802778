import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineLibraryAdd } from "react-icons/md";

import { FaHome } from "react-icons/fa";
import { BiCategoryAlt } from "react-icons/bi";
import { RiUserSettingsFill } from "react-icons/ri";
import { TbBrandReactNative } from "react-icons/tb";
import logo from "../img/vlogo1.jpg";

const Menu = () => {
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/admin" className="brand-link">
          <img
            src={logo}
            alt="Admin"
            className="brand-image img-circle elevation-3 "
            style={{ opacity: ".8" }}
          />
          <span className="brand-text font-weight-light">Super Admin</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          <nav className="mt-2">
            {/*================================ Dashboard ==========================*/}
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item has-treeview">
                <Link to="/admin" className="nav-link">
                  <span>
                    <FaHome />
                  </span>
                  <p>Dashboard</p>
                </Link>
              </li>
              {/*============================ product Setting Start ================================*/}

              <li className="nav-item has-treeview">
                <a className="nav-link">
                  <span>
                    <RiUserSettingsFill />
                  </span>
                  <p>
                    Certificate
                    <i className="right fas fa-angle-down arrowsize"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <BiCategoryAlt />
                      </span>
                      <p>
                        Certificate Details
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/addDetails" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Details</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/listDetails" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Details</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link
                          to="/admin/add-bulk-cetificates"
                          className="nav-link"
                        >
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add-Bulk-Cetificates</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/*============================ product Setting end ================================*/}
              <li className="nav-item has-treeview">
                <a className="nav-link">
                  <span>
                    <RiUserSettingsFill />
                  </span>
                  <p>
                    Contact
                    <i className="right fas fa-angle-down arrowsize"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <BiCategoryAlt />
                      </span>
                      <p>
                        Contact Details
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Details</p>
                        </Link>
                      </li>
                    </ul>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/listContact" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>List Details</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/*============================ Banner Start ================================*/}

              <li className="nav-item has-treeview">
                <a className="nav-link">
                  <span>
                    <RiUserSettingsFill />
                  </span>
                  <p>
                    Slider-Banners
                    <i className="right fas fa-angle-down arrowsize"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link className="nav-link">
                      <span>
                        <BiCategoryAlt />
                      </span>
                      <p>
                        Banners
                        <i className="right fas fa-angle-down arrowsize"></i>
                      </p>
                    </Link>
                    <ul className="nav nav-treeview productDesign">
                      <li className="nav-item">
                        <Link to="/admin/addBanner" className="nav-link">
                          <span>
                            <MdOutlineLibraryAdd />
                          </span>
                          <p>Add Banners</p>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              {/*============================ Banner end ================================*/}
              {/*============================ Notification Start ================================*/}

              <li className="nav-item has-treeview">
                <a className="nav-link">
                  <span>
                    <RiUserSettingsFill />
                  </span>
                  <p>
                    Notification
                    <i className="right fas fa-angle-down arrowsize"></i>
                  </p>
                </a>
                <ul className="nav nav-treeview productDesign">
                  <li className="nav-item">
                    <Link to="/admin/addNotification" className="nav-link">
                      <span>
                        <MdOutlineLibraryAdd />
                      </span>
                      <p>Add Notifications</p>
                    </Link>
                  </li>
                </ul>
              </li>
              {/*============================ Notification end ================================*/}
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};
export default Menu;
