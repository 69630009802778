import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  registrationTotal:  [],

  registrationTotalLoading: true,
};

export const getRegistration = createAsyncThunk(
  "Registration/getRegistration",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/registration/all`;
      const resp = await axios(url);
      return resp.data.register;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const registrationPost = createAsyncThunk(
  "registration/registrationPost",
  async (formData, thunkAPI) => {
    try {
        const config = {
            Headers: { "Content-Type": "application/json" },
          };
      const url = `${Baseurl}/api/v1/registration/new`;
      const resp = await axios.post(url, formData,config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Registration Not create");
    }
  }
);

const RegistrationSlice = createSlice({
  name: "registerAll",
  initialState,
  extraReducers: {
    [getRegistration.pending]: (state) => {
      state.registrationTotalLoading = true;
    },
    [getRegistration.fulfilled]: (state, action) => {
      state.registrationTotal = action.payload;

     
      state.registrationTotalLoading = false;
    },
    [getRegistration.rejected]: (state, action) => {
      state.registrationTotalLoading = true;
    },

    //registration post
    [registrationPost.pending]: (state) => {
      state.registrationTotalLoading = true;
    },

    [registrationPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.registrationTotal = [
          ...state.registrationTotal,
          action.payload.register,
        ].sort();

       
      }
      state.registrationTotalLoading = false;
    },

    [registrationPost.rejected]: (state, action) => {
      state.registrationTotalLoading = true;
    },
  },
});

export default RegistrationSlice.reducer;
