import React, { Fragment, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { registrationPost } from "../../redux/registration/RegistrationSlice";
import { string } from "yup";

const AddDetails = () => {
  const dispatch = useDispatch();

  const [certificate, setCertificate] = useState("");
  const [name, setName] = useState("");
  const [fname, setFname] = useState("");
  const [course, setCourse] = useState("");
  const [grade, setGrade] = useState("");
  const [date, setDate] = useState("");

  const handlesubmit = async (e) => {
    e.preventDefault();

    const formData = {
      certificate: String(certificate),
      name: String(name),
      fname: String(fname),
      course: String(course),
      grade: String(grade),
      date: String(date),
    };
    console.log(formData);
    const data = await dispatch(registrationPost(formData));

    if (!data) {
      alert("please check the flields");
    } else {
      if (data.payload.success) {
        alert("Certificate Added");
        setCertificate("");
        setName("");
        setFname("");
        setCourse("");
        setGrade("");
        setDate("");
      } else {
        alert(data.message);
      }
    }
  };

  return (
    <Fragment>
      <div className="content-wrapper">
        <div className="employeesContainer">
          <form onSubmit={handlesubmit}>
            <div className="card m-4">
              <div className="card-footer">
                <h5>Add Certificate</h5>
              </div>
              <div className="card-body">
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Certificate Number *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Certificate Number"
                      value={certificate}
                      required
                      onChange={(e) => setCertificate(e.target.value)}
                    />
                  </Col>
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">Name *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      value={name}
                      required
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Father Name *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Father Name "
                      value={fname}
                      required
                      onChange={(e) => setFname(e.target.value)}
                    />
                  </Col>

                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Course *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Course"
                      value={course}
                      required
                      onChange={(e) => setCourse(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row className="g-2  m-2">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">Grade *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Grade"
                      value={grade}
                      required
                      onChange={(e) => setGrade(e.target.value)}
                    />
                  </Col>

                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Date of Completion *
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Date of Completion"
                      value={date}
                      required
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>
              <div className="card-footer ">
                <button className="btn btn-primary" type="submit">
                  Add Certificate
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AddDetails;
