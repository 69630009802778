import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  bannerTotal: [],

  desktopimage: "",
  desktopimageLoading: true,

  bannerTotalLoading: true,
  bannerLoading: true,
  delbannerLoading: true,
};

export const getBanner = createAsyncThunk(
  "Banner/getBanner",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/banner/all`;
      const resp = await axios(url);
      return resp.data.banners;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const bannerPost = createAsyncThunk(
  "banner/bannerPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/banner/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("banner Not create");
    }
  }
);

export const bannerDesktopImages = createAsyncThunk(
  "banner/bannerDesktopImages",
  async (formData, thunkAPI) => {
    try {
      const config = {
        // Headers: { "Content-Type": "application/json" },
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=AaB03x" +
            "--AaB03x" +
            "Content-Disposition: file" +
            "Content-Type: png/jpg/jpeg" +
            "Content-Transfer-Encoding: binary" +
            "...data... " +
            "--AaB03x--",
          Accept: "application/json",
          type: "formData",
        },
      };
      const url = `${Baseurl}/api/v1/banner/bannerimg`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("bannerimg Not create");
    }
  }
);

export const bannerDelete = createAsyncThunk(
  "banner/bannerDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/banner/${id}`;
      const resp = await axios.delete(url, id, config);

      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("slider Not create");
    }
  }
);

const BannerSlice = createSlice({
  name: "BannerAll",
  initialState,
  extraReducers: {
    [getBanner.pending]: (state) => {
      state.bannerTotalLoading = true;
    },
    [getBanner.fulfilled]: (state, action) => {
      state.bannerTotal = action.payload;
      state.bannerTotalLoading = false;
      state.bannerLoading = false;
      localStorage.setItem("bannerTotal", JSON.stringify(state.bannerTotal));
    },
    [getBanner.rejected]: (state, action) => {
      state.bannerTotalLoading = true;
    },
    //post start============================
    [bannerPost.pending]: (state) => {
      state.bannerLoading = true;
    },

    [bannerPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.bannerTotal = [...state.bannerTotal, action.payload.banner];
        localStorage.setItem("bannerTotal", JSON.stringify(state.bannerTotal));
      }
      state.bannerLoading = false;
    },
    [bannerPost.rejected]: (state, action) => {
      state.bannerLoading = true;
    },

    //post end ====================

    [bannerDesktopImages.pending]: (state) => {
      state.desktopimageLoading = true;
    },
    [bannerDesktopImages.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.desktopimage = action.payload.desktopImages;
      }
      state.desktopimageLoading = false;
    },
    [bannerDesktopImages.rejected]: (state, action) => {
      state.desktopimageLoading = true;
    },

    //delete start ====================
    [bannerDelete.pending]: (state) => {
      state.delbannerLoading = true;
    },

    [bannerDelete.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.bannerTotal = state.bannerTotal.filter(
          (banner) => banner._id !== action.payload.id
        );
        localStorage.setItem("bannerTotal", JSON.stringify(state.bannerTotal));
      }
      state.delbannerLoading = false;
    },

    [bannerDelete.rejected]: (state, action) => {
      state.delbannerLoading = true;
    },
  },
});

export default BannerSlice.reducer;
