import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  contactTotal:  [],

  contactTotalLoading: true,
};

export const getContact = createAsyncThunk(
    "Contact/getContact",
    async (thunkAPI) => {
      try {
        const url = `${Baseurl}/api/v1/contact/all`;
        const resp = await axios(url);
        return resp.data.contact;
      } catch (error) {
        return thunkAPI.rejectWithValue("404 Not Found");
      }
    }
  );

  
const ContactSlice = createSlice({
    name: "ContactAll",
    initialState,
    extraReducers: {
      [getContact.pending]: (state) => {
        state.contactTotalLoading = true;
      },
      [getContact.fulfilled]: (state, action) => {
        state.contactTotal = action.payload;
  
       
        state.contactTotalLoading = false;
      },
      [getContact.rejected]: (state, action) => {
        state.contactTotalLoading = true;
      },
    },
  });
  
  export default ContactSlice.reducer;