import "./App.css";
import { Route, Routes, HashRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import MainLayout from "./components/layout/MainLayout";
import Dashboard from "./pages/Dashboard";
import Error from "../src/pages/Error";
import Loginpage from "./pages/Loginpage";
import AddDetails from "./pages/blog/AddDetails";
import ListDetails from "./pages/blog/ListDetails";
import { getRegistration } from "./redux/registration/RegistrationSlice";
import ListContacts from "./pages/contact/ListContact";
import { getContact } from "./redux/contact/ContactSlice";
import AddBulkCetificates from "./pages/blog/AddBulkCetificates";
import AddBanner from "./pages/Banner/AddBanner";
import { getBanner } from "./redux/banner/BannerSlice";
import AddNotification from "./pages/notification/AddNotification";
import { getNotification } from "./redux/notification/NotificationSlice";
function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    const initialSetup = async () => {
      try {
        // getRegistration
        dispatch(getRegistration());
        // getContact
        dispatch(getContact());
        // getBanner
        dispatch(getBanner());
        // getNotification
        dispatch(getNotification());

      } catch (error) {}
    };
    initialSetup();
  }, [dispatch]);
 

  return (
    <div className="wrapper">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Loginpage />} />
          <Route path="/admin" exact element={<MainLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="/admin/addDetails" element={<AddDetails />} />
            <Route path="/admin/listDetails" element={<ListDetails />} />
            <Route path="/admin/listContact" element={<ListContacts />} />
            <Route path="/admin/addBanner" element={<AddBanner />} />
            <Route path="/admin/addNotification" element={<AddNotification />} />

            <Route path="/admin/add-bulk-cetificates" element={<AddBulkCetificates />} />

          
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
