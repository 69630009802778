import React from "react";
import { BsPersonCircle } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../redux/Athentication/AuthSlice";

const Header = () => {
  const { isAuth, name } = useSelector((store) => store.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signout = () => {
    dispatch(authActions.signout());
    navigate("/");
  };
  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
       
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu">
              <i className="fas fa-bars" />
            </a>
          </li>
        </ul>
        <ul className="headerDesign">
          {isAuth ? (
            <li onClick={() => signout()}>
              <span>
                <BiLogOut />
              </span>
              <span className="m-2 mt-2">{name}</span>
            </li>
          ) : (
            <Link to="/login">
              <li>
                <span>
                  <BsPersonCircle />
                </span>
              </li>
            </Link>
          )}
        </ul>
      </nav>
    </div>
  );
};
export default Header;
