import React, { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import {
  bannerDelete,
  bannerDesktopImages,
  bannerPost,
} from "../../redux/banner/BannerSlice";

const AddBanner = () => {
  const { bannerTotal, desktopimage, desktopimageLoading } = useSelector(
    (store) => store.BannerAll
  );
  console.log(bannerTotal, "bannerTotal");
  console.log(desktopimage, "desktopimage");

  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [show, setShow] = useState(Boolean(0));
  const [checkurl, setCheckurl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");

  const desktopChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(bannerDesktopImages({ desktopImage: reader.result }));
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const dispatch = useDispatch();

  const verifyurl = async (e) => {
    const value = e.target.value;
    setName(value);
    setCheckurl("");
    const BANNERurl = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setUrl(BANNERurl);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (name !== "") {
      const formData = {
        name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        DeskImg: desktopimage,
        show: show,
      };
      console.log(formData);
      dispatch(bannerPost(formData));
    }
  };

  const deleteClick = async (e, id) => {
    const data = await dispatch(bannerDelete(id));

    if (data.payload.success) {
      alert("Banner Deleted");
      // window.location.reload(false);
    } else {
      alert(data.message);
      console.log(data.payload.error);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "BANNER NAME",
      width: 250,
      align: "center",
      headerAlign: "center",
    },

    {
      headerName: "DESKTOP IMAGE",
      width: 300,
      headerAlign: "center",

      renderCell: (params) => {
        return (
          <>
            <img
              className="d-flex m-auto"
              src={params.row.DeskImg}
              width={100}
              alt="desktopimage"
            />
          </>
        );
      },
    },

    {
      field: "ACTION.",
      width: 240,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              onClick={(e) => deleteClick(e, cellValues.row._id)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Banner</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                      Banner Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        required
                        onChange={(e) => verifyurl(e)}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label>Desktop Image</Form.Label>
                      <Form.Control
                        type="file"
                        required
                        onChange={desktopChange}
                      />
                      <p className="text-center mt-2">
                        * The image width and height should be 1350px * 562px
                      </p>
                      <div>
                        {desktopimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={desktopimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col className="d-flex ">
                      <div className="me-4">
                        <input
                          type="checkbox"
                          className="me-1"
                          value="show"
                          checked={show === true}
                          onChange={(e) => {
                            setShow(e.target.checked);
                          }}
                        />
                        <Form.Label>Show</Form.Label>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {name !== "" && !desktopimageLoading ? (
                    <button className="btn btn-primary" type="submit">
                      Add Banner
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Banner
                    </button>
                  )}
                </div>
              </div>
            </form>

            <div className="card me-2 ms-2">
              <div
                style={{ height: 650, width: "100%" }}
                className="d-flex m-auto text-align-center"
              >
                <DataGrid
                  rows={bannerTotal}
                  columns={columns}
                  getRowId={(row) => row._id}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  //   checkboxSelection
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddBanner;
