import React, { useEffect } from "react";
import { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import {
  bannerDelete,
  bannerDesktopImages,
  bannerPost,
} from "../../redux/banner/BannerSlice";
import {
  notificationDelete,
  notificationPost,
} from "../../redux/notification/NotificationSlice";

const AddNotification = () => {
  const { notificationTotal } = useSelector((store) => store.NotificationAll);

  const [name, setName] = useState("");
  const [notification, setNotification] = useState("");
  const [url, setUrl] = useState("");
  const [show, setShow] = useState(Boolean(0));
  const [checkurl, setCheckurl] = useState("");
  const [error, setError] = useState("");
  const [errorcolor, setErrorcolor] = useState("red");

  const dispatch = useDispatch();

  const verifyslugurl = (e) => {
    const value = e.target.value;
    setName(value);
    const catSlug = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");
    setUrl(catSlug);
  };

  const notificationChange = (e) => {
    const value = e.target.value;
    setNotification(value);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (name !== "") {
      const formData = {
        name: name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
        msg: notification,
        show: show,
      };
      console.log(formData);
      dispatch(notificationPost(formData));
    }
  };

  const deleteClick = async (e, id) => {
    const data = await dispatch(notificationDelete(id));

    if (data.payload.success) {
      alert("Notification Deleted");
    } else {
      alert(data.message);
      console.log(data.payload.error);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "S. No",
      filterable: false,
      renderCell: (index) => index.api.getRowIndex(index.row._id) + 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "NOTIFICATION NAME",
      width: 200,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "msg",
      headerName: "NOTIFICATION",
      width: 350,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "ACTION.",
      width: 240,
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              onClick={(e) => deleteClick(e, cellValues.row._id)}
              color="error"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card me-2 ms-2">
                <div className="card-footer">
                  <h5>Add Notification</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    <Col md>
                      <Form.Label htmlFor="disabledTextInput">
                        Notification Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        value={name}
                        required
                        onChange={verifyslugurl}
                      />
                      <p style={{ color: errorcolor }} className="mt-2">
                        {error}
                      </p>
                    </Col>
                    <Col md>
                      <Form.Label>Notification</Form.Label>
                      <Form.Control
                        type="text"
                        as="textarea"
                        placeholder="Enter Your Message..."
                        style={{ height: "50" }}
                        required
                        value={notification}
                        onChange={notificationChange}
                      />

                      <p className="text-center mt-2">
                        {/* * The image width and height should be 1350px * 562px */}
                      </p>
                      {/* <div>
                        {desktopimageLoading ? (
                          <div></div>
                        ) : (
                          <img
                            src={desktopimage}
                            width={150}
                            height={150}
                            alt="img"
                          />
                        )}
                      </div> */}
                    </Col>
                  </Row>
                  <Row className="g-2  m-2">
                    <Col className="d-flex ">
                      <div className="me-4">
                        <input
                          type="checkbox"
                          className="me-1"
                          value="show"
                          checked={show === true}
                          onChange={(e) => {
                            setShow(e.target.checked);
                          }}
                        />
                        <Form.Label>Show</Form.Label>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="card-footer ">
                  {name !== "" && notification !== "" ? (
                    <button className="btn btn-primary" type="submit">
                      Add Notification
                    </button>
                  ) : (
                    <button className="btn btn-primary" disabled type="submit">
                      Add Notification
                    </button>
                  )}
                </div>
              </div>
            </form>

            <div className="card me-2 ms-2">
              <div
                style={{ height: 650, width: "100%" }}
                className="d-flex m-auto text-align-center"
              >
                <DataGrid
                  rows={notificationTotal}
                  columns={columns}
                  getRowId={(row) => row._id}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  //   checkboxSelection
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddNotification;
