import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  notificationTotal: [],

  notificationLoading: true,
  notificationLoading: true,
  delnotificationLoading: true,
};

export const getNotification = createAsyncThunk(
  "Notification/getNotification",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/notification/all`;
      const resp = await axios(url);
      return resp.data.notifications;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

export const notificationPost = createAsyncThunk(
  "notification/notificationPost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/notification/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("notification Not create");
    }
  }
);

export const notificationDelete = createAsyncThunk(
  "notification/notificationDelete",
  async (id, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/notification/${id}`;
      const resp = await axios.delete(url, id, config);

      const myreturn = {
        success: resp.data.success,
        id: id,
      };
      return myreturn;
    } catch (error) {
      return thunkAPI.rejectWithValue("notification Not create");
    }
  }
);

const NotificationSlice = createSlice({
  name: "NotificationAll",
  initialState,
  extraReducers: {
    [getNotification.pending]: (state) => {
      state.notificationLoading = true;
    },
    [getNotification.fulfilled]: (state, action) => {
      state.notificationTotal = action.payload;
      state.notificationLoading = false;
      state.notificationLoading = false;
      localStorage.setItem(
        "notificationTotal",
        JSON.stringify(state.notificationTotal)
      );
    },
    [getNotification.rejected]: (state, action) => {
      state.notificationLoading = true;
    },
    //post start============================
    [notificationPost.pending]: (state) => {
      state.notificationLoading = true;
    },

    [notificationPost.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.notificationTotal = [
          ...state.notificationTotal,
          action.payload.notification,
        ];
        localStorage.setItem(
          "notificationTotal",
          JSON.stringify(state.notificationTotal)
        );
      }
      state.notificationLoading = false;
    },
    [notificationPost.rejected]: (state) => {
      state.notificationLoading = true;
    },

    //post end ====================

    //delete start ====================
    [notificationDelete.pending]: (state) => {
      state.delnotificationLoading = true;
    },

    [notificationDelete.fulfilled]: (state, action) => {
      if (action.payload.success) {
        state.notificationTotal = state.notificationTotal.filter(
          (banner) => banner._id !== action.payload.id
        );
        localStorage.setItem(
          "notificationTotal",
          JSON.stringify(state.notificationTotal)
        );
      }
      state.delnotificationLoading = false;
    },

    [notificationDelete.rejected]: (state) => {
      state.delnotificationLoading = true;
    },
  },
});

export default NotificationSlice.reducer;
