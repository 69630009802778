import React, { Fragment, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { registrationPost } from "../../redux/registration/RegistrationSlice";
import { string } from "yup";
import { read, utils, XLSX } from "xlsx";

const AddBulkCetificates = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [suceessmassag, setSuceessmassage] = useState("");

  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);
    console.log(datas, "datas");
    setData(datas);
  };

  const handlesubmit = async (e) => {
    let number = 0;
    for (let i in data) {
      let myrow = data[i];
      const formData = {
        certificate: myrow.certification_no,
        name: myrow.name,
        fname: myrow.fathers_name,
        course: myrow.course_name,
        grade: myrow.qrade,
        date: myrow.data_of_completion,
      };
      const grocerybulk = await dispatch(registrationPost(formData));

      if (grocerybulk.payload.success) {
        number = number++;
        setSuceessmassage(`${number} ${myrow.name}  upload successfull`);
      }
    }
  };

  return (
    <Fragment>
      <div className="content-wrapper">
        <div className="employeesContainer">
          <form onSubmit={handlesubmit}>
            <div className="card m-4">
              <div className="card-footer">
                <h5>Add Bulk Cetificates</h5>
              </div>
              <div className="card-body">
                <Row className="g-2  m-2 w-50">
                  <Col md>
                    <Form.Label htmlFor="disabledTextInput">
                      Upload Excel File *
                    </Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="enter name....."
                      onChange={(e) => handlefile(e)}
                    />
                  </Col>
                </Row>
              </div>
              <div className="card-footer ">
                <button className="btn btn-primary" type="submit">
                  Add Bulk Cetificates
                </button>
              </div>
              <p style={{ color: "green" }}>{suceessmassag}</p>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AddBulkCetificates;
